body {
  overflow-y: scroll;
}

#wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#header {
  height: 60px;
  padding: 6px 0;
}

#content {
  flex: 1;
}

#content-center {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

#footer {
  height: 45px;
  padding: 13px 16px;
}
